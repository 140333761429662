import React from "react";
import "./ClientsCard.css";
function ClientsCard({ image, name, link, motion }) {
  return (
    <div className={`clients-card ${motion}`}>
      <div
        className="image"
        // style={{
        //     backgroundImage:`url(${image})`
        // }}
      >
        <img src={image} alt="" />
      </div>
      <div className="client-name">
        <p>{name}</p>
      </div>
    </div>
  );
}

export default ClientsCard;
