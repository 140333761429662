import React from 'react'
import "./Navbar.css"
import logo from "assets/image/logo.png";
import logotext from "assets/image/logo-text.png";
import { Close, Menu } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
function Navbar() {
    const handleShowMenu = (e) => {
        document.getElementById("nav-links").classList.add("--display-navlinks");
        document.getElementById("menu-icon").classList.add("--hide");
      };
      const handleHideMenu = (e) => {
        document.getElementById("nav-links").classList.remove("--display-navlinks");
        document.getElementById("menu-icon").classList.remove("--hide");
      };
  return (
    < >
        <section className="navbar-section">
        <div className="scroll-bar"></div> 

        <div className="navbar">
          <div className="logo-section">
            <img className={"logo"} src={logo} alt="anbessa-logo" />
            <img className="text" src={logotext} alt="" />
          </div>
          <div id="menu-icon" onClick={handleShowMenu}>
            <Menu/>
          </div>
          <div className="nav-links" id="nav-links">
            <ul>
              <div id="close-icon" onClick={handleHideMenu}>
                <Close/>
              </div>

              <li onClick={handleHideMenu}> 
                <HashLink to="/#">About</HashLink>
              </li>
              <li onClick={handleHideMenu}>
                <HashLink to="/#services">Services</HashLink> 
              </li>
              <li onClick={handleHideMenu}>
                <HashLink to="/#portifolio">Clients</HashLink>  
              </li>
              <li onClick={handleHideMenu}>
                <HashLink to="/#contacts">Contact</HashLink>  
              </li>
              <li onClick={handleHideMenu}>
                <Link to="/blog"> Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </ >
  )
}

export default Navbar