import Particle from "components/particles/Particle";
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import "./Homepage.css";
import BlogCard from "components/BlogCard.jsx/BlogCard";
import axios from "axios";
import Footer from "./Footer/Footer";
import Loading from "components/Loading/Loading";

function Blogs() {
  const [blogList, setBlogList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState([]);
  const [filterValuem, setFilterValue] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const blogPerPage = 6;

  const [categories, setCategories] = useState([]);

  const [choosenCategory, setChoosenCategory] = useState("Category");
  const [choosenRegion, setChoosenRegion] = useState("Region");

  const getRef = async () => {
    await axios.get("https://anbessa.prismic.io/api/v2").then((ref) => {
      console.log(ref.data.refs[0].ref, "reference");
      getBlog(ref.data.refs[0].ref);
    });
  };
  const getBlog = async (ref) => {
    await axios
      .get(
        `https://anbessa.prismic.io/api/v2/documents/search?ref=${ref}#format=json`
      )
      .then((ref) => {
        console.log(ref.data.results, "data fetch");
        const anbessaBlogTypes = ref.data.results.filter(
          (blog) => blog.type == "anbessa_blog"
        );
        setCategories(getListOfCategories(anbessaBlogTypes));
        setBlogList(anbessaBlogTypes);
        setFiltered(anbessaBlogTypes);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    getRef();
  }, []);

  const handelFiltereByKeyWord = (e) => {
    setFilterValue(e.target.value);

    setFiltered(
      JSON.parse(JSON.stringify(blogList))
        // .filter((blog) => blog.data["blog-image"] != null)
        .filter(
          (blog) =>
            blog.data.title[0].text
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) > -1
        )
        .filter((blog) =>
          choosenCategory == "Category"
            ? blog
            : blog.data.category.indexOf(choosenCategory) > -1
        )
    );

  };
  const handelFiltereByCategory = (e) => {
    setChoosenCategory(e.target.value);
    setFiltered(
      JSON.parse(JSON.stringify(blogList))
        .filter((blog) =>
          e.target.value == "Category"
            ? blog
            : blog.data.category.indexOf(e.target.value) > -1
        )
        .filter((blog) =>
          filterValuem == ""
            ? blog
            : blog.data.title[0].text
                .toLowerCase()
                .indexOf(filterValuem.toLowerCase()) > -1
        )
         
    );
  };
   
  const handleResetPressed = ()=>{
    setFilterValue("");
    setChoosenCategory("Category");
    setFiltered(blogList)

  }

  const paginateHandler = (page) => {
    const newlist = filtered.slice(
      blogPerPage * page,
      blogPerPage * page + blogPerPage
    );
    // setVisibleBlogs(newlist);
  };

  useEffect(() => {
    if (isLoaded) {
      paginateHandler(pageNumber);
    }
  }, [isLoaded]);

  const getListOfCategories = (blogsList) => {
    const listOFCategories = ["Category"];
    blogsList.map((blog) => {
      listOFCategories.push(blog.data.category);
    });
    return listOFCategories;
  };
  return (
    <div className="sub-body">
      <Navbar />
      <div
        className="particles-container"
        style={{
          color: "black",
          background: "white",
        }}
      >
        <Particle />
      </div>
      <div className="section-parent">
        <p className="section-title">
          RECENT
          <span>BLOGS</span>
        </p>
        <div className="--flex-center-center-col">
          <div>
            <input
              type="text"
              placeholder="Search by keyword"
              value={filterValuem}
              onChange={handelFiltereByKeyWord}
            />
            <select 
            className="selector"
            
            value={choosenCategory} onChange={handelFiltereByCategory}>
              {categories.map((state) => {
                return <option>{state}</option>;
              })}
            </select>
          </div>
          <div
          style={{
            paddingBottom:"2rem"
          }}
          >
            <button
             className="btn"
              onClick={() => {
                // handelFiltereByKeyWord({ target: { value: "" } });
                // handelFiltereByCategory({ target: { value: "Category" } }); 
                handleResetPressed()
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <button
            onClick={() => {
              if (pageNumber - 1 >= 0) {
                setPageNumber(pageNumber - 1);
              }
            }}
          >
            {"<"}
          </button>
          {filtered.length <= blogPerPage ? (
            <> 1</>
          ) : (
            Array.from(
              { length: Math.ceil(filtered.length / blogPerPage) },
              (x, i) => i
            ).map((p) => (
              <div
                style={{
                  color: p == pageNumber ? "white" : "gray",
                }}
              >
                {p + 1}
              </div>
            ))
          )}
          <button
            onClick={() => {
              if (Math.ceil(filtered.length / blogPerPage) > pageNumber + 1) {
                setPageNumber(pageNumber + 1);
              }
            }}
          >
            {">"}
          </button>
        </div>
        {isLoaded ? (
          <div className="blogs-list">
            {[
              ...filtered.slice(
                blogPerPage * pageNumber,
                blogPerPage * pageNumber + blogPerPage
              ),
            ].map((blog) => (
              <BlogCard blog={blog} />
            ))}
          </div>
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Blogs;

const blogs = [
  {
    id: 0,
    image:
      "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Is it over for papers",
    paragraph:
      "Veniam occaecat sunt aliqua occaecat ipsum officia ullamco id reprehenderit Lorem consectetur nulla laboris. Voluptate nisi aliqua deserunt ea anim do deserunt amet. Laboris eiusmod consequat exercitation incididunt qui anim nulla. Esse sunt nostrud est id aute. Occaecat sunt minim esse adipisicing elit commodo ut nostrud laboris minim. Ullamco laborum laborum veniam elit qui in dolore fugiat.",
  },
  {
    id: 0,
    image:
      "https://images.unsplash.com/photo-1487611459768-bd414656ea10?q=80&w=2880&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Is it over for papers",
    paragraph:
      "Veniam occaecat sunt aliqua occaecat ipsum officia ullamco id reprehenderit Lorem consectetur nulla laboris. Voluptate nisi aliqua deserunt ea anim do deserunt amet. Laboris eiusmod consequat exercitation incididunt qui anim nulla. Esse sunt nostrud est id aute. Occaecat sunt minim esse adipisicing elit commodo ut nostrud laboris minim. Ullamco laborum laborum veniam elit qui in dolore fugiat.",
  },
  {
    id: 0,
    image:
      "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Is it over for papers",
    paragraph:
      "Veniam occaecat sunt aliqua occaecat ipsum officia ullamco id reprehenderit Lorem consectetur nulla laboris. Voluptate nisi aliqua deserunt ea anim do deserunt amet. Laboris eiusmod consequat exercitation incididunt qui anim nulla. Esse sunt nostrud est id aute. Occaecat sunt minim esse adipisicing elit commodo ut nostrud laboris minim. Ullamco laborum laborum veniam elit qui in dolore fugiat.",
  },
  {
    id: 0,
    image:
      "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Is it over for papers",
    paragraph:
      "Veniam occaecat sunt aliqua occaecat ipsum officia ullamco id reprehenderit Lorem consectetur nulla laboris. Voluptate nisi aliqua deserunt ea anim do deserunt amet. Laboris eiusmod consequat exercitation incididunt qui anim nulla. Esse sunt nostrud est id aute. Occaecat sunt minim esse adipisicing elit commodo ut nostrud laboris minim. Ullamco laborum laborum veniam elit qui in dolore fugiat.",
  },
  {
    id: 0,
    image:
      "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Is it over for papers",
    paragraph:
      "Veniam occaecat sunt aliqua occaecat ipsum officia ullamco id reprehenderit Lorem consectetur nulla laboris. Voluptate nisi aliqua deserunt ea anim do deserunt amet. Laboris eiusmod consequat exercitation incididunt qui anim nulla. Esse sunt nostrud est id aute. Occaecat sunt minim esse adipisicing elit commodo ut nostrud laboris minim. Ullamco laborum laborum veniam elit qui in dolore fugiat.",
  },
];
