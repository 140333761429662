import React, { useEffect } from "react";
import "./Hero.css";
import { urls } from "consts/images";

function Hero() {
  useEffect(() => {
    handleMouseMove();
  }, []);
  const handleMouseMove = () => {
    let mouseX = 0;
    let mouseY = 0;
    let flashlight = document.getElementById("flashlight");
    //Detect touch device
    const isTouchDevice = () => {
      try {
        //We try to create TouchEvent(it would fail for desktops and throw error)
        document.createEvent("TouchEvent");
        return true;
      } catch (e) {
        return false;
      }
    };
    function getMousePosition(e) {
      mouseX = !isTouchDevice() ? e.pageX : e.touches[0].pageX;
      mouseY = !isTouchDevice() ? e.pageY : e.touches[0].pageY;
      flashlight.style.setProperty("--Xpos", mouseX + "px");
      flashlight.style.setProperty("--Ypos", mouseY + "px");
    }
    document.addEventListener("mousemove", getMousePosition);
    document.addEventListener("touchmove", getMousePosition);
  };

  const handleMouseLeave = ()=>{
    let flashlight = document.getElementById("flashlight");

    flashlight.classList.add("--transparent")

  }
  const handleMouseOver = ()=>{
    let flashlight = document.getElementById("flashlight");

    flashlight.classList.remove("--transparent")

  }
  return (
    <section className="hero" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <div className="flash">
        <div id="flashlight"></div>
      </div>
      <div className="text">
        <p className="we-are-anbessa">WE ARE ANBESSA</p>
        <p className="your-one-stop">YOUR ONE STOP</p>
        <p className="solution">SOLUTION</p>
        <p className="hero-paragraph">
          A full-service digital marketing and IT solutions provider that helps
          businesses to grow and thrive in the digital world. We specialize in
          helping companies to optimize their IT infrastructure and leverage
          technology to drive growth and efficiency, and we also help businesses
          to reach and engage with their target audience through a range of
          online marketing channels.
        </p>
      </div>
      <div className="hero-img">
        <div className="image-shadow"></div>
        <img src={urls.lionHero} alt="" />
      </div>
    </section>
  );
}

export default Hero;
