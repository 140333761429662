import { Check, CheckBoxOutlineBlank, CheckCircle } from "@mui/icons-material";
import { Dialog, Paper } from "@mui/material";
import React, { useEffect } from "react";
import "./EmailResult.css" 
import { styled } from "@mui/material/styles";
const StyledPaper = styled(Paper)`
background-color: green;
`;
function EmailResult({ isSuccess, onClose }) {
  useEffect(() => {
    setTimeout(() => {
        onClose()
      }, 5000);
  }, []);
  return (
    <Dialog open={true}  
    onClose={onClose}
    sx={{ //You can copy the code below in your theme
        // background: '#000',
        '& .MuiPaper-root': {
          background: '#000'
        },
        '& .MuiBackdrop-root': {
        //   backgroundColor: 'transparent' // Try to remove this to see the result
        }
      }}
    >
      <div className="email-result">
        {isSuccess ? (
          <div className="email-container">
            <Check style={{fontSize:"80px"}} />
            <h3> Thank you, for reaching out!</h3>
            <p>{`Our staff will get back to you ASAP.`}</p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Dialog>
  );
}

export default EmailResult;
