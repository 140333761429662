import React from "react";
import "./Footer.css";
import logo from "assets/image/logo.png";
import phone from "assets/image/phone.png";
import address from "assets/image/address.png";
import ig from "assets/image/instagram.png";
import lnk from "assets/image/linkedin.png";
import facebook from "assets/image/facebook.png";
import logotext from "assets/image/logo-text.png";
import {
  Facebook,
  FacebookOutlined,
  Instagram,
  LinkedIn,
  Twitter,
  X,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faSquareInstagram} from "@fortawesome/react-fontawesome"

function Footer() {
  return (
    <div className="footer-parent">
      <img className="top-footer-logo" src={logo} alt="" />
      <div className="--display-center-row">
        <div
          className="underline-footer-top-reverse"
          style={{ width: "200px" }}
        ></div>
        <img className="top-footer-logo-text" src={logotext} alt="" />

        <div className="underline-footer-top" style={{ width: "200px" }}></div>
      </div>
      <div className="--display-center-row socials">
        <a href="https://www.instagram.com/anbessait/" target="blank">
          <div className="social-icon">
            <Instagram />
          </div>
          <div className="social-text">Instagram</div>
        </a>
        <a
          href="https://www.facebook.com/anbessaitsolutions/"
          target="blank"
        >
          <div className="social-icon">
            <FacebookOutlined />
          </div>
          <div className="social-text">
            Facebook
          </div>

        </a>
        <a
          href="https://www.linkedin.com/company/anbessaitsolutions/"
          target="blank"
        >
          <div className="social-icon">

          <LinkedIn /></div>
          <div className="social-text">
            LinkedIn
          </div>

        </a>
        <a
          href="https://twitter.com/anbessait"
          target="blank"
        >
          <div className="social-icon">
          
          <Twitter /></div>
          <div className="social-text">
            Twitter
          </div>

        </a>
      </div>
      <div className="--display-center-row">
        <div
          className="underline-footer-top-reverse"
          style={{ width: "400px" }}
        ></div>
        <div className="underline-footer-top" style={{ width: "400px" }}></div>
      </div>

      <section className="footer">
        <div className="contact">
          <p>
            <img src={phone} alt="phone" />
            <a href="tel:+251988040388"> 0988040388</a>
          </p>
        </div>
        <div className="--display-center-column mobile-row">
          <div className="underline-footer-vertical"></div>

          <div className="underline-footer-vertical-reverse"></div>
        </div>

        <div className="email">
          <p>contact@anbessait.com</p>
        </div>
        <div className="--display-center-column mobile-row">
          <div className="underline-footer-vertical"></div>
          <div className="underline-footer-vertical-reverse"></div>
        </div>

        <div className="social-media">
          {" "}
          <p>
            <img src={address} alt="phone" />
            Addis Ababa, Ethiopia
          </p>
        </div>
      </section>
      <div className="--display-center-row">
        <div
          className="underline-footer-top-reverse"
          style={{ width: "400px" }}
        ></div>
        <div className="underline-footer-top" style={{ width: "400px" }}></div>
      </div>
      <section className="bottom-footer">
        <div className="underline-footer-reverse"></div>
        <div className="footer-logo">
          <img src={logo} alt="" />
        </div>
        <p>YOUR ONE STOP SOLUTION FOR IT SERVICE</p>
        <div className="underline-footer "></div>
      </section>
    </div>
  );
}

export default Footer;
