import React, { useState } from "react";
import "./Form.css";
import CustomSelector from "components/CustomeSelector/CustomSelector";
import {
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import EmailResult from "views/Contact/EmailResult";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [what, setWht] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const handleSubmit = async (x) => {
    x.preventDefault()
    setIsLoading(true);
    // code fragment
    var data = {
      service_id: "service_zxp8r9g",
      template_id: "template_k2c5ag5",
      user_id: "t-90d5NUvJBbNqSy3",
      template_params: {
        username: "James",
        "g-recaptcha-response": "03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...",
        to_name: "Anbessa Chat",
        from_name: `${name} ${email}`,
        message: `${menuItems[selected].name}:${what}`,
      },
    };

    await axios
      .post(
        "https://api.emailjs.com/api/v1.0/email/send",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function () {
        setIsLoading(false);
        setIsResultVisible(true);

        // alert("Your mail is sent!");
        setName("");
        setEmail("");
        setPhone("");
        setWht("");
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsError(true);
        setIsResultVisible(true);

        console.log(error);
        // alert("Oops... " + JSON.stringify(error));
      });
    // code fragmentv
  };

  const [selected, setSelected] = useState(0);
  const menuItems = [
    {
      id: 0,
      name: "What service are you intereseted in?",
      disabled: true,
    },
    {
      id: 1,
      name: "Digital Marketing",
      disabled: false,
    },
    {
      id: 2,
      name: "Web Development",
      disabled: false,
    },
    {
      id: 3,
      name: "Software Development",
      disabled: false,
    },
    {
      id: 4,
      name: "Strategic Consolutancy",
      disabled: false,
    },
    {
      id: 5,
      name: "Social Media Boosting",
      disabled: false,
    },
    {
      id: 6,
      name: "Branding",
      disabled: false,
    },
    {
      id: 7,
      name: "Other",
      disabled: false,
    },
  ];
  const sumbitText = "send"
  return (
    <div className="form">
      {isLoading ? (
        <Dialog
          open
          sx={{
            //You can copy the code below in your theme
            // background: '#000',
            "*": {
              background: "transparent !important",
              boxShadow:"none"
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.282) !important", // Try to remove this to see the result
            },
          }}
        >
          <div
            className="--display-center-column"
            style={{ width: "100px", height: "100px", }}
          >
            <CircularProgress sx={{ color:"#ffa800"}}/>
          </div>
        </Dialog>
      ) : null}
      {isResultVisible ? (
        <EmailResult
          isSuccess={true}
          onClose={() => {
            setIsResultVisible(false);
            setIsError(false);
          }}
        />
      ) : null}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          required
          placeholder="Your Name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          value={email}
          required

          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="text"
          value={phone}
          required
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Phone"
        />
        <div style={{ width: "100%" }}>
          {/* <CustomSelector
          required={true}
          label={"What service are you intereseted in?"}
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          menuItems={menuItems}
        />  */}
          <select
            className="selector"
            placeholder="What service are you intereseted in?"
            value={selected}
            required
            onChange={(e) => {
              // console.log(e.target)
              setSelected(e.target.value);
            }}
          >
            {menuItems.map((item) => (
              <option disabled={item.disabled} value={item.id}>
                <div> {item.name}</div>
              </option>
            ))}
          </select>
        </div>
        {selected == 7 ? (
          <input
          required

            type="text"
            value={what}
            placeholder="Tell us how we can be of help"
            onChange={(e) => setWht(e.target.value)}
          />
        ) : null}

        <button type='submit' className="btn" disabled={selected===0}  style={{cursor:selected===0?"no-drop":"pointer"}}>
          SUBMIT
        </button>
      </form>
    </div>
  );
}
