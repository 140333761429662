import React from "react";

import "./Homepage.css";

import Footer from "./Footer/Footer";
import Contact from "./Contact/Contact";
import TrustedBy from "./TrustedBy/TrustedBy";
import Hero from "./Hero/Hero";
import Navbar from "./Navbar/Navbar";
import Services from "./Services/Services";
import Clients from "./Clients/Clients";

function Homepage() {
  return (
    <>
      <section className="sub-body">
        <Navbar />
        <Hero />
        <TrustedBy />
        <Services />
        <Clients />
        <Contact />
        <Footer />
      </section>
    </>
  );
}

export default Homepage;
