import React from "react";
import "./ServicePopup.css";
import { Dialog, DialogTitle } from "@mui/material";
import Slide from '@mui/material/Slide';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {ReactComponent as Collaps} from "assets/svg/collapse_content_FILL0_wght400_GRAD0_opsz24.svg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function ServicePopup({ isOpen, onClose, title, paragraph, children }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root": {
          background: "#000",
        },
        "& .MuiBackdrop-root": {
        //   backgroundColor: "transparent",
        },
      }}
    >
      <div className="dialog-parent">

        <div className="dialog-sub-parent">
            <div className="close-icon" onClick={onClose}>
            <Collaps style={{filter: `invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%)`}}/>

            </div>
      <DialogTitle>{title}</DialogTitle>
            {children}
            <p className="detail-paragraph">{paragraph}</p>
            
            </div>
      </div>
    </Dialog>
  );
}

export default ServicePopup;




