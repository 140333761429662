import Preloader from "components/Preloader/Preloader";
import Homepage from "./views/Homepage";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Blogs from "views/Blogs";
import ScrollToTop from "utils/ScrollToTop";
import { useEffect } from "react";

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-EV2TBSLCY3', {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

const App = () => {
  return (
    <div className="App">
      <Router> 
        <GoogleAnalytics/>
        <Preloader />
        <ScrollToTop/>
        <Routes> 
         <Route path="/" element={<Homepage />}/>
         <Route path="/blog" element={<Blogs />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
