import React from "react";
import "./Services.css";
import ServiceCard from "components/services-card/ServiceCard";
import Particle from "components/particles/Particle";
import webdev from "assets/image/webdev.png";
import software from "assets/image/softwaredev.png";
import market from "assets/image/digitalmarketing.png";
import brand from "assets/image/branding.png";
import boost from "assets/image/boosting.png";
import consult from "assets/image/consultancy.png";
import { ReactComponent as WebDesign} from "assets/svg/Property 1=Websie Development.svg";
import { ReactComponent as SoftwareDesign} from "assets/svg/Property 1=Software Development.svg";
import { ReactComponent as DigitalMarketing} from "assets/svg/Property 1=Digital Marketing.svg";
import { ReactComponent as Branding} from "assets/svg/Property 1=Branding.svg";
import { ReactComponent as Consultancy} from "assets/svg/Property 1=Consultancy.svg";
import { ReactComponent as Boost} from "assets/svg/Property 1=Boosting.svg";

function Services() {
  return (
    <>
      <section className="our-service section-parent" id="services">
        <div className="particles-container" style={{
          color:"black", background:"white"
        }}>
    <Particle/>
          
        </div>
        <p className="section-title">
          OUR
          <span>SERVICES</span>
        </p>
        <div className="cards">
          <ServiceCard
            image={<DigitalMarketing/>}
            title={"Digital Marketing"}
            list={`We offer comprehensive social media marketing services that help businesses and
              organizations achieve their marketing goals through various social media
              platforms such as Facebook, Instagram, TikTok, Twitter, and others. Our services
              include creating and executing effective social media strategies, managing and
              growing a brand's social media presence, and engaging with customers and
              followers to build brand awareness and loyalty. Our team of experts stays up-todate with the latest trends and technologies in the field to ensure that our clients
              are always ahead of the curve.`}
          />
          <ServiceCard
            image={<WebDesign/>}
            title={"Website Development"} 
            list={`Our website development services are specially designed to cater to the unique
              needs of businesses of all sizes. Our team of skilled web developers stays updated
              with the latest technologies to create custom solutions that can enhance your
              online presence. Utilizing advanced tools and methods, we build websites that are
              aesthetically pleasing, user-friendly, and responsive. In addition to website design
              and development, we also offer ongoing website maintenance and support
              services to ensure that our clients' websites remain up-to-date, secure, and
              functioning optimally.`}
          />

          <ServiceCard
            image={<SoftwareDesign/>}
            title={"Software Development"}
            list={`We provide comprehensive software development services to help businesses and
              organizations reach their digital transformation goals. Our team of experienced
              developers utilizes the latest technologies and methodologies to create custom
              software solutions that meet the unique needs of each client. Our services cover
              the entire software development life cycle, from requirements gathering and
              planning to design, development, testing, deployment, and maintenance. We offer
              a wide range of software development services including custom application
              development, web development, mobile application development, cloud
              computing solutions, and more.
              `}
              gear={true}
          />
          <ServiceCard
            image={<Consultancy/>}
            title={"Strategic Consultancy"}
            list={`We offer comprehensive business strategy consultancy services to help
              organizations achieve their business goals. We work with you to develop and
              implement strategies that improve operational efficiency, drive growth, and
              position your business for long-term success. Our services include market
              analysis, competitive analysis, customer insights, financial analysis, and more.
              We collaborate with you to identify opportunities for improvement and develop
              actionable plans to achieve your goals.`}
          />
          <ServiceCard
            image={<Boost/>}
            title={"Social Media Boosting"}
            list={`Target the right audience: No more shouting into the void! We use laser-focused targeting to put your voice right in the ears of your ideal customers, ensuring maximum impact.`}
          />
          <ServiceCard
            image={<Branding/>}
            title={"Branding"}
            list={`Uncover your brand's essence: What makes you stand out? What story are you yearning to tell? We'll guide you through a self-discovery process to pinpoint your core values and differentiators.`}
          />
        </div>
      </section>
    </>
  );
}

export default Services;
